<script setup lang="ts">
/********************
 * PROPS & EMITS     *
 ********************/
export interface CiCampsiteSectionAwardsLabelsProps {
  awards?: any;
}
const props = withDefaults(defineProps<CiCampsiteSectionAwardsLabelsProps>(), {
  awards: () => [],
});
</script>

<template>
  <section>
    <div class="container">
      <h2
        key="section-headline-awards"
        class="px-6 text-center text-black"
      >
        {{ $pgettext('Award / Label section headline', 'Awards') }}
      </h2>
    </div>
    <div class="awards-labels__slider__container m-auto lg:w-fit lg:max-w-[930px] xl:max-w-[1100px]">
      <LazyVisibleCiCssSlider class="mt-12" hydrate-on-visible>
        <div
          v-for="(award, index) in props.awards"
          :key="index"
          class="slide snap-center first:ml-[15px] last:mr-[15px] lg:snap-start"
          :data-idx="index + 1"
        >
          <div class="flex justify-center">
            <div class="awards-labels__award mx-4 my-0 mb-4 w-[110px]">
              <div
                class="flex aspect-square size-[110px] items-center justify-center overflow-hidden rounded-full border border-gray bg-white"
              >
                <img
                  v-if="award.image"
                  class="h-auto w-full"
                  :draggable="false"
                  :src="award.image"
                  :alt="award.name"
                />
              </div>
              <div class="mt-4 text-center">
                {{ award.name }}
              </div>
            </div>
          </div>
        </div>
      </LazyVisibleCiCssSlider>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.awards-labels__slider__container {
  @include media-breakpoint-only(sm) {
    .slide:first-of-type {
      margin-left: calc((100vw - 540px) / 2 + 15px);
    }

    .slide:last-of-type {
      margin-right: calc((100vw - 540px) / 2 + 15px);
    }
  }

  @include media-breakpoint-only(md) {
    .slide:first-of-type {
      margin-left: calc((100vw - 720px) / 2 + 15px);
    }

    .slide:last-of-type {
      margin-right: calc((100vw - 720px) / 2 + 15px);
    }
  }
}
</style>
