<script setup lang="ts">
// icons
import { faChevronRight, faInfoCircle, faStar } from '@fortawesome/pro-solid-svg-icons';

// components
import CiCampsiteDetailProposeChange from '@/components/campsitedetail/CiCampsiteDetailProposeChange/CiCampsiteDetailProposeChange.vue';
import CiOpeningVisualization from '@/components/campsitedetail/CiCampsiteSectionAbout/CiOpeningVisualization.vue';
import CiFanclubDetails from '@/components/fanclub/CiFanclubDetails.vue';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiCampsiteSectionAboutProps {
  campsite: any;
  hasAds?: boolean;
  returnClick: boolean;
}
const props = withDefaults(defineProps<CiCampsiteSectionAboutProps>(), {
  hasAds: false,
});

const emit = defineEmits<{
  'button-clicked': [];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const localePath = useLocalePath();
const route = useRoute();
const { $gettext } = useGettext();
const { locale } = useI18n();
const { openModal, closeModal } = useCiModal();
const { hasOperatingHours, openingDatesAsDates, openings, openingRanges } = useCampsiteDetailOperatingHours({
  campsite: () => props.campsite,
});

/********************
 * REFS & VARS       *
 ********************/
const description = computed(() => {
  if (!props.campsite || !props.campsite.meta) {
    return '';
  }
  return props.campsite.meta.plain_description || '';
});

const translationLanguage = computed(() => {
  if (!props.campsite || !props.campsite.meta) {
    return null;
  }

  return props.campsite.meta.translations_lang || null;
});

const video = computed(() => {
  return props.campsite?.media?.video || null;
});

const mainSeasonPrice = computed(() => {
  return props.campsite.price?.price1;
});

const offSeasonPrice = computed(() => {
  return props.campsite.price?.price_off_season;
});

const campingcard = computed(() => {
  return props.campsite.price?.campingcard;
});

const inquiriesAllowed = computed(() => {
  return props.campsite.meta?.inquiries_allowed;
});

const groundDescription = computed(() => {
  return props.campsite.descriptive?.ground_composition_predominantly?.message;
});

const i18nCategoryTooltip = computed(() => {
  return $gettext(
    '<h6 class="text-medium">How is the category assigned?</h6><small>There are two options for classification:<ul class="list-unstyled mt-2"><li class="mb-2">1. Classification by national institutions.</li><li>2. The campsite operator assigns a category by self-assessment. Here he is urged to proceed as objectively as possible.</li></ul></small>',
  );
});

/********************
 * FUNCTIONS         *
 ********************/
function showCampingCardModal() {
  const CiCampingCardInfoModal = defineAsyncComponent({
    loader: () => import('@/components/CiCampingCardModal/CiCampingCardInfoModal.vue'),
  });

  openModal(
    {
      component: CiCampingCardInfoModal,
      attrs: {
        name: 'CiCampingCardInfoModal',
        campsiteName: props.campsite.meta.name,
        campsiteSlug: props.campsite.slug,
        campingcard: campingcard.value,
        onClose: () => {
          closeModal('CiCampingCardInfoModal');
        },
      },
    },
    {
      clickToClose: true,
      contentTransition: 'translate-y-down',
      contentClass: 'max-w-[600px] w-full h-auto',
    },
  );
}
</script>

<template>
  <section>
    <h2
      key="section-headline-description"
      class="text-center"
    >
      {{ $gettext('Description') }}
    </h2>
    <div class="container mt-12 grid grid-cols-3 gap-x-[30px]">
      <div class="col-span-3 mb-6 lg:col-span-2 lg:mb-0">
        <CiTextExcerpt
          :excerpt="description"
          :html="props.campsite.meta.description || ''"
          :language="translationLanguage"
        >
          <template #headline>
            <h3>{{ $gettext('Profile') }}</h3>
          </template>
          <template #fallback>
            <div>
              <p :lang="locale">
                {{ $gettext('No description yet.') }}
              </p>
            </div>
          </template>
        </CiTextExcerpt>

        <!-- fanclub -->
        <section
          v-if="props.campsite.fanclub && props.campsite.fanclub.benefits"
          id="fanclub"
          class="pt-6"
        >
          <CiFanclubDetails :items="props.campsite.fanclub.text" />
        </section>

        <!-- bug: https://stackoverflow.com/questions/27128476/changing-vimeo-embeds-iframe-src-adds-to-history-api -->
        <!-- fixed with https://web.dev/iframe-lazy-loading/ -->
        <client-only v-if="video">
          <div
            id="video"
            class="row my-4"
          >
            <div class="col-12">
              <div
                id="video"
                class="aspect-video rounded"
              >
                <iframe
                  :src="`${video.embed_url}?rel=0`"
                  class="size-full"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  title="Video"
                  allowfullscreen
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </client-only>

        <!-- meta -->
        <div class="my-6" data-nosnippet>
          <ul class="m-0 list-none p-0">
            <li
              v-if="props.campsite.meta.category"
              class="m-0 p-0 pb-1"
            >
              <div class="flex flex-row items-center">
                <span class="font-medium">{{ $gettext('Campsite category') }}</span>:
                <div class="ml-2 flex flex-row items-center">
                  <CiAwesomeIcon
                    v-for="n in props.campsite.meta.category"
                    :key="n"
                    :icon="faStar"
                    class="mr-1 fill-warning"
                    ratio="0.7"
                  />
                </div>

                <div class="ml-2">
                  <client-only>
                    <CiTooltip>
                      <CiAwesomeIcon
                        :icon="faInfoCircle"
                        class="fill-primary"
                        ratio="0.8"
                      />
                      <template #tooltip>
                        <div
                          style="max-width: 400px"
                          v-html="i18nCategoryTooltip"
                        />
                      </template>
                    </CiTooltip>
                  </client-only>
                </div>
              </div>
            </li>
            <li
              v-if="props.campsite.meta.size"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{ $gettext('Size of the site') }}</span>:
              <span>
                {{ new Intl.NumberFormat(locale).format(props.campsite.meta.size) }} ha
                <span
                  v-if="groundDescription"
                  class="text-info"
                >(<span>{{ $gettext('Ground conditions') }}</span>: {{ groundDescription }})</span></span>
            </li>
            <li
              v-if="props.campsite.count.count_space_cars !== null"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{ $gettext('Total number of pitches') }}</span>:
              <span>{{ props.campsite.count.count_space_cars }}</span>
            </li>
            <li
              v-if="props.campsite.count.count_space_tourism !== null"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{ $gettext('Number of tourist pitches') }}</span>:
              <span>{{ props.campsite.count.count_space_tourism }}</span>
              <span
                v-if="
                  props.campsite.count.count_space_tourism_separated !== null
                    && props.campsite.count.count_space_tourism
                    && props.campsite.count.count_space_tourism_separated !== 0
                "
                class="text-info"
              >
                (<span>{{ $gettext('Of which parcels') }}</span>: {{ props.campsite.count.count_space_tourism_separated }})</span>
            </li>
            <li
              v-if="props.campsite.equipment.motorhome_pitches_outside_gate !== null"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{ $gettext('Motorhome pitches in front of the gates') }}</span>:
              <span v-if="props.campsite.count.count_motorhome_pitches_outside_gate !== null">{{
                props.campsite.count.count_motorhome_pitches_outside_gate
              }}</span>
              <span v-else-if="props.campsite.equipment.motorhome_pitches_outside_gate">{{
                $gettext('Available')
              }}</span>
              <span
                v-else
                class="text-warning"
              >{{ $gettext('Not available') }}</span>
            </li>
            <li
              v-if="props.campsite.equipment.caravan_pitches_outside_gate !== null"
              class="m-0 p-0 pb-1"
            >
              <span class="font-medium">{{
                $gettext('Stopover pitches in front of the gates also for caravans')
              }}</span>:
              <span v-if="props.campsite.equipment.caravan_pitches_outside_gate">{{ $gettext('Available') }}</span>
              <span
                v-else
                class="text-warning"
              >{{ $gettext('Not available') }}</span>
            </li>
          </ul>
        </div>

        <CiCampsiteDetailProposeChange
          class="mb-6 block"
          data-tracking-id="about-suggest-change"
        />
      </div>

      <div class="col-span-3 mb-6 lg:col-span-1 lg:mb-0" data-nosnippet>
        <!-- price -->
        <template v-if="mainSeasonPrice || offSeasonPrice || (campingcard && campingcard.rate)">
          <h3 id="reference-price">
            {{ $gettext('Reference price') }}
          </h3>
          <div>
            <div v-if="mainSeasonPrice">
              <span>{{ $gettext('Main season') }}</span>
              {{ new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(mainSeasonPrice) }}*
            </div>
            <div v-if="offSeasonPrice">
              <span>{{ $gettext('Low season') }}</span>
              {{ new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(offSeasonPrice) }}*
            </div>

            <div v-if="campingcard && campingcard.rate">
              <CiCampingCardLogo class="inline" />
              <span
                class="relative ml-1"
                style="top: 2px"
              >
                <span>{{ $gettext('CampingCard ACSI') }}</span>
                <span class="ml-1">{{
                  new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(campingcard.rate)
                }}</span>
                <a
                  href="#"
                  class="relative ml-1 inline-block"
                  style="top: 3px"
                  aria-label="info"
                  @click.prevent="showCampingCardModal"
                ><CiAwesomeIcon
                  :icon="faInfoCircle"
                  class="fill-primary"
                  ratio="0.8"
                /></a>
              </span>
            </div>

            <div class="text-body-book mt-6 text-gray">
              <template v-if="mainSeasonPrice || offSeasonPrice">
                <sup>*</sup>
                <span>{{ $gettext('Two adults, caravan, car, electricity and local tax per night') }}</span>
              </template>
            </div>
          </div>
        </template>

        <!-- availability link -->
        <div
          v-if="inquiriesAllowed || props.returnClick"
          class="mt-2"
        >
          <button
            v-if="returnClick"
            type="button"
            class="button button--primary w-full"
            @click="emit('button-clicked')"
          >
            <span>{{ $gettext('Check availability') }}</span>
          </button>
          <nuxt-link
            v-else-if="inquiriesAllowed"
            class="button button--primary w-full"
            :to="localePath({ name: 'campsite-identifier-inquire', params: route.params, query: route.query })"
          >
            <span>{{ $gettext('Send inquiry') }}</span>
            <CiAwesomeIcon
              :icon="faChevronRight"
              ratio="0.6"
            />
          </nuxt-link>
        </div>
      </div>

      <div class="col-span-3 mt-12 md:mt-0" data-nosnippet>
        <!-- season time -->
        <template v-if="hasOperatingHours">
          <h3>{{ $gettext('Operating hours') }}</h3>
          <div
            v-for="(dates, idx) in openingDatesAsDates"
            :key="idx"
          >
            <div>{{ dates.start.format('DD. MMMM') }} - {{ dates.end.format('DD. MMMM') }}</div>
          </div>
          <client-only>
            <CiOpeningVisualization
              :opening="openings"
              :opening-ranges="openingRanges"
              class="mt-6"
            />
          </client-only>
        </template>
      </div>
    </div>

    <!-- ad -->
    <div
      v-if="props.hasAds"
      class="container my-6"
    >
      <CiAdSlot
        id="camping_content_4"
        class="ad--h-250"
      />
    </div>
  </section>
</template>

<style></style>
